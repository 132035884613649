.sidebar.collapse {
  @apply w-[54px];

  .items {
    @apply grow;
  }

  .accountBox {
    @apply p-0 rounded-full w-[42px] h-[42px];
  }
  .name,
  .email {
    line-height: 0;
  }
}

.sidebar {
  @apply w-[215px] h-full max-h-full pl-3 py-2 relative;
  @apply bg-transparent text-chip-bg-active;
  @apply flex flex-col shrink-0 transition-[width] ease-in-out;
}
.mobileSidebar {
  @apply flex sm:hidden flex-col shrink-0;
  @apply max-w-[280px] absolute z-20 w-full h-full max-h-full left-0 top-0;
  @apply bg-bg text-text-def-white;
  @apply border-r-[1px] border-white border-opacity-15 p-3;
  @apply transition-[transform_opacity] duration-300 ease-in-out opacity-100;
}
.mobileSidebar.collapse {
  @apply -translate-x-full opacity-0;
}
.underlay {
  @apply sm:hidden grid-cols-2;
  @apply absolute bg-bg bg-opacity-80 z-[11] w-dvw h-dvh top-0 left-0;
  @apply transition-opacity duration-300 ease-in-out;
}
.underlay.collapse {
  @apply opacity-0 pointer-events-none;
}

.header {
  @apply w-full mb-8;
  @apply flex items-start gap-3;
}
.menuButton {
  @apply shrink-0 cursor-pointer p-3 select-none;
  @apply hover:bg-item-bg-hover active:bg-item-bg-active rounded-2xl text-text-def-white;
  @apply active:scale-[0.95];
}
.headerImg {
  @apply w-full h-[70px] mx-auto;

  .logo {
    @apply h-full mx-auto rounded-2xl overflow-x-hidden min-w-[149px] select-none;
  }
}

.items {
  @apply flex flex-col shrink-0 mb-2 gap-0.5 overflow-y-auto overflow-hidden text-text-def-white;
  @apply [&::-webkit-scrollbar]:w-2;
}
.item {
  @apply w-full p-2.5 px-3 rounded-2xl shrink-0;
  @apply text-icon-default text-sm font-medium;
  @apply flex items-center justify-start gap-4 cursor-pointer;
  @apply hover:bg-item-bg-hover hover:text-text-def-white active:bg-item-bg-active;
  @apply select-none overflow-hidden;
  @apply active:scale-[0.99];
  transition: justify-content 500ms;
  transition-delay: 500ms;

  .icon {
    @apply shrink-0;
  }
  .selected {
    @apply active:bg-item-bg-active;
  }
}

.label,
.name,
.email {
  @apply overflow-hidden whitespace-nowrap w-full;
  line-height: 1.25;
}

.selected {
  @apply bg-gradient-to-b from-item-bg-start to-item-bg-end text-item-selected;
  @apply active:bg-none hover:text-item-selected;
}

/** Box **/
.accountBox {
  @apply w-full h-full rounded-2xl border-white border-[1px] border-opacity-5;
  @apply bg-gradient-to-br from-box-bg to-box-shading;
  @apply shadow-[inset_0_0_10px] shadow-box-shading;
  @apply flex items-center gap-3 p-3;
}

@media(max-height: 500px) {
  .accountBox, .headerImg {
    @apply hidden;
  }
}

.avatar {
  @apply flex justify-center items-center text-text-def-white;
  @apply w-[42px] h-[42px] bg-item-bg-hover rounded-full shrink-0 shadow-[inset_0_0_4px] shadow-box-shading;
}
.avatarIcon {
  @apply rounded-b-full;
}
.name {
  @apply text-sm font-medium overflow-hidden w-2/3 text-ellipsis;
}
.email {
  @apply text-xs font-normal !text-item-list-button w-1/2 overflow-hidden text-ellipsis;
}

/** Footer **/
.footer {
  @apply flex flex-col gap-3;
}
