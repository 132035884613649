.content {
  @apply w-full h-full relative;
  @apply grow flex flex-col;
}

.bg {
  @apply w-full h-full sm:rounded-2xl overflow-hidden;
  @apply flex;
}

.header {
  @apply w-full h-[58px] p-3 px-2;
  @apply flex items-center justify-between gap-3;
  @apply border-box-button-border border-opacity-10 border-b-[1px];
}

.headerButton, .modelButton {
  @apply shrink-0 cursor-pointer p-3 text-chip-text-light select-none;
  @apply flex items-center justify-center gap-1.5;
  @apply hover:bg-chip-bg active:bg-chip-bg-active rounded-2xl;
  @apply active:scale-[0.95];
}
.modelButton {
  @apply font-bold text-box-button-border;
}

.chat {
  @apply w-full h-full z-10;
  @apply bg-chat-bg sm:rounded-2xl;
  @apply flex flex-col;
  @apply overflow-y-auto;

  .header {
    @apply flex-row-reverse justify-between sm:flex-row;
  }
}