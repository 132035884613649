.core {
  @apply flex h-dvh w-full;
}

[data-theme="dark"] {
  @apply text-white;

  .sidebarItem {
    @apply hover:bg-black-800 hover:bg-opacity-50 active:!bg-opacity-100;
  }
  .sidebarItem.selected {
    @apply bg-black-800 bg-opacity-80;
  }
  .menuButton {
    @apply hover:bg-black-800 text-inherit !bg-opacity-50 active:!bg-opacity-100;
  }
  .account {
    @apply bg-black-1100;
  }

  .collapsed .darkButton {
    @apply hidden;
  }
}

[data-theme="light"] {
  .collapsed .lightButton {
    @apply hidden;
  }
}

.collapsed .lightButton .sidebarLabel,
.collapsed .darkButton .sidebarLabel {
  @apply hidden;
}

.collapsed .themeContainer,
.collapsed .themeContainer {
  @apply w-[48px] h-[40px] flex justify-center items-center;
}

.lightButton,
.darkButton {
  @apply text-sm;
}

.collapsed {
  .sidebar {
    @apply w-[80px];
  }
  .sidebarLabel,
  .logo {
    @apply hidden;
  }

  .sidebarItem {
    @apply justify-start p-3 pl-3.5 my-[1px];
  }

  .account {
    @apply bg-transparent p-0 pl-2.5;
    .accountBubble {
      @apply w-7 h-7;
    }
    .accountDetails {
      @apply hidden;
    }
  }
}

.chatTopBar {
  @apply w-full px-4 pt-4 pb-2 relative flex gap-3;
  font-size: 15px;
}

.sidebar {
  @apply flex flex-col justify-between h-full p-4 shrink-0 relative;
  @apply w-[275px];
  @apply transition-[width];
}

.sidebarHeader {
  @apply flex h-[90px] w-full gap-3  mb-8 shrink-0 overflow-hidden;
}

.menuButton {
  @apply flex justify-center items-center;
  @apply p-3.5 rounded-2xl aspect-square w-fit h-fit;
  @apply text-black-50 text-opacity-95 !bg-opacity-30 active:!bg-opacity-50;
  @apply hover:bg-slate-50;
  @apply cursor-pointer;
}

.logo {
  @apply object-contain object-top w-full rounded-2xl  !select-none;
}

.sidebarCollection {
  @apply h-full flex flex-col justify-between;
}

.sidebarItems {
  @apply flex flex-col gap-1;
}
.sidebarItem {
  @apply flex items-center gap-4 p-3 pl-3.5 text-base flex-nowrap overflow-hidden h-[40px];
  @apply hover:rounded-2xl hover:bg-slate-50 hover:bg-opacity-40 active:bg-opacity-50;
  @apply cursor-pointer text-sm;
  svg {
    @apply shrink-0;
  }
}
.sidebarItem.selected {
  @apply bg-slate-50 bg-opacity-50 font-semibold;
  @apply rounded-2xl active:bg-opacity-30;
}
.sidebarLabel {
  @apply select-none text-nowrap;
}

.sidebarFooter {
  @apply flex flex-col mb-4 gap-1;
}

.account {
  @apply flex items-center gap-3;
  @apply p-3.5 rounded-2xl;
  @apply bg-slate-50;
}

.accountBubble {
  @apply flex justify-center items-center w-[46px] h-[46px] bg-gray-300 rounded-full shrink-0;
}

.accountDetails {
  @apply flex flex-col my-auto overflow-hidden;
}
.accountName {
  @apply font-medium;
}
.accountEmail {
  @apply font-light text-sm;
}

.accountName,
.accountEmail {
  @apply w-full whitespace-nowrap text-ellipsis overflow-hidden;
}
