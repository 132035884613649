.shine {
    @apply inline-flex justify-center whitespace-nowrap rounded-lg;
    @apply px-3.5 py-2.5;
    @apply text-sm font-medium text-slate-200 dark:text-slate-800;
    @apply bg-gradient-to-r from-slate-800 to-slate-700 dark:from-slate-200 dark:to-slate-100 dark:hover:bg-slate-100;
    @apply shadow focus:outline-none focus:ring focus:ring-slate-500/50 focus-visible:outline-none focus-visible:ring focus-visible:ring-slate-500/50;
    @apply relative before:absolute before:inset-0 before:rounded-[inherit];
    @apply before:bg-[linear-gradient(45deg,transparent_25%,theme(colors.white/.5)_50%,transparent_75%,transparent_100%)];
    @apply dark:before:bg-[linear-gradient(45deg,transparent_25%,theme(colors.white)_50%,transparent_75%,transparent_100%)];
    @apply before:bg-[length:250%_250%,100%_100%] before:bg-[position:200%_0,0_0] before:bg-no-repeat;
    @apply before:[transition:background-position_0s_ease] hover:before:bg-[position:-100%_0,0_0] hover:before:duration-[1500ms];
}

.default {
    @apply bg-black-50 text-white py-3 px-6 rounded-2xl font-medium shadow-md;
}
.default:hover {
    @apply bg-black-1000;
}

.secondary {
    @apply bg-white text-black-50 py-3 px-6 rounded-2xl font-medium shadow-md;
    @apply border-[1px] border-gray-300;
}
.secondary:hover {
    @apply bg-gray-50;
}

.default, .secondary {
    @apply transition-transform duration-200 ease-in-out;
}
.default:active, .secondary:active {
    @apply scale-95;
}