.navbar {
  @apply w-full fixed top-0 z-50 py-6 flex justify-center;
}

.content {
  @apply container flex justify-between items-center mx-4;
}

.logo {
  @apply text-base font-bold;
}

.actions {
  @apply sm:flex gap-4 hidden;
}

.menu {
  @apply sm:hidden flex justify-center items-center w-0 h-0 leading-[0] p-4;
}
