.container {
  @apply w-full h-full max-h-full flex flex-col items-center justify-end relative;
  @apply px-4 pb-4 overflow-hidden relative;
}

.chat {
  @apply w-full grow pt-4 absolute h-full px-4;
  @apply overflow-y-auto overflow-x-hidden;
  scrollbar-width: thin;
}
.messages {
  @apply w-full max-w-3xl flex flex-col gap-6 mx-auto grow;
  @apply min-h-full h-fit pt-12 pb-[140px];
}

.input {
  max-height: 100%;
  @apply resize-none;
  @apply w-full;
  @apply flex items-center justify-between gap-3 text-sm;
  @apply border-none bg-transparent;
  @apply pl-4 pt-2 rounded-tl-2xl rounded-none focus:outline-none;
}

.inputContainer {
  max-height: 40cqh;
  @apply mx-auto w-full max-w-3xl p-2 pl-0 pt-0 bg-fg relative;
  @apply border-box-button-border border-opacity-10 border-[1px] rounded-2xl;
}

.sendButton {
  @apply flex justify-center items-center aspect-square;
  @apply p-2 bg-blues-normal rounded-xl text-fg;
  @apply hover:bg-blues-hover active:bg-blues-active;
  @apply active:scale-[0.95] cursor-pointer;
}

.userMessage,
.agentMessage {
  @apply flex gap-2;
}
.agentMessage {
  > div {
    @apply bg-chip-bg bg-opacity-60 text-text-dark rounded-r-[20px] rounded-tl-md rounded-bl-3xl p-3;
  }
}
.userMessage {
  @apply max-w-[min(calc(100%-125px),calc(80dvw-75px))];
  > div {
    @apply bg-blues-normal text-fg rounded-l-[20px] rounded-br-md rounded-tr-3xl p-3;
  }
}

.avatar {
  @apply w-[42px] h-[42px] shrink-0 rounded-full p-2 border-[1px] border-chip-bg border-opacity-60 flex justify-center items-center;
}
