@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
  --background-color: #0E0E10; /* Default theme */
}

[data-theme='dark'] {
  --background-color: #0E0E10; /* Dark theme */
}

[data-theme='light'] {
  --background-color: #FFFFFF; /* Light theme */
}

html, body {
  background-color: var(--background-color);
}

.hide-scrollbar::-webkit-scrollbar {
    display: none;
}
.hide-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

  .phone-input-container, .PhoneInput {
    @apply flex w-full items-center;
  }
  
  .phone-input-container .PhoneInputInput {
    @apply grow border-[1.5px] focus:border-opacity-50 px-3 py-4 placeholder-gray-500 text-black-900 rounded-2xl focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm;
  }

  .phone-input-container .PhoneInputCountryIcon {
    color: black;
    margin-top: 10px;
  }
  .PhoneInputCountrySelect {
    @apply flex justify-center items-center;
  }
  .PhoneInputCountrySelectArrow{
    color: black;
    margin-top: 10px;
    margin-right: 4px;
  }


  .ph-input-container {
    background-color: transparent;
    border-radius: 8px; 
    width: 400px;
    display: flex;
    align-items: center;
  }
  
  .ph-input-container .PhoneInputInput {
    background: #3B364B; 
    border: none; 
    outline: none;
    padding: 10px; 
    border-radius: 8px; 
    width: 400px;
    margin-top: 12px;
    color: white; 
  }
  
  .ph-input-container .PhoneInputInput::placeholder {
    color: white; 
    opacity: 30%;
    font-size: 14px;
  }
  .ph-input-container .PhoneInputCountryIcon {
    color: white;
    margin-top: 10px;
  }
  .PhInputCountrySelectArrow{
    color: white;
    margin-top: 10px;
    margin-right: 4px;
  }

  .drag-over {
    border: 2px dashed #4c4c4c;
    background-color: rgba(0, 0, 0, 0.1);
  }
  .audio-recorder{
    width: 32px !important;
    height : 32px !important;
 
    border-radius: 16px !important;
    display: flex ;
    flex-direction: row;
    justify-content: center;
    color: white !important;
  }

  .audio-recorder.recording{
   
    display: flex ;
    flex-direction: row;
    justify-content: right;
  }

  .audio-recorder-mic{
    background: white !important;
    height: 10px !important;
    margin: 8px 0 8px 0 !important;
  }

  .audio-recorder-timer {
    color: white !important;
  }


  .audio-recorder-status {
    color: white !important;
    margin-right: 10px !important;
  }

  .audio-recorder-options{
    background: white !important;
    height: 10px !important;
    margin: 8px 0 8px 0 !important;

  }

  .shepherd-element {
    @apply bg-chat-bg shadow-lg !rounded-2xl;
    color: var(--text-color);
  }

  .shepherd-text {
    @apply text-sm font-medium;
  }

  .shepherd-button {
    @apply !rounded-xl text-base font-semibold active:scale-95;
  }

  button.shepherd-button-example-primary {
    @apply text-opacity-100 !text-text-light bg-blues-normal hover:bg-blues-hover active:bg-blues-active;
  }