@import url('./css/colors.css');
@import url('./css/typography.css');

html, body, #root {
    min-height: 100dvh;
    overflow-x: hidden;

    font-family: "Inter", serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}

:root {
    --text-light: #f5f5f5;
    --text-dark: #1a1c1e;
    --text-def-white: #f5f5f5;

    --bg: #151718;
    --bg-2: #181a1c;

    --fg: #fefefe;
    --fg-shading: #f5f5f5;

    --chip-bg: #e5e8ee;
    --chip-bg-active: #dfe2e8;
    --chip-text-light: #6e7273;

    --icon-default: #717075;
    --icon-chat: #717676;

    --box-bg: #242627;
    --box-shading: #1a1e1f;
    --box-fg: #151718;
    --box-button-border: #363a3b;
    --box-button-border-hover: #3b3f40; /* 5% lighter */
    --box-button-border-active: #3f4344; /* 5% lighter */

    --item-bg-start: #323337;
    --item-bg-end: #0b2e30;
    --item-bg-hover: #2e3133; /* 5% lighter */
    --item-bg-active: #2f3234; /* 5% lighter */
    --item-selected: #0084ff;

    --item-list-header: #3b4242;
    --item-list-button: #7b7f82;
    --item-list-button-hover: #8b8f92; /* 10% lighter */

    --chat-bg: #f3f5f7;

    --green: #3ddd77;

    --blue-normal: #0084ff;
    --blue-hover: #0f8bff;
    --blue-active: #1f93ff;
}

[data-theme="light"] {
    --text-light: #f5f5f5;
    --text-dark: #1a1c1e;
    --text-def-white: #f5f5f5;

    --bg: #151718;
    --bg-2: #181a1c;

    --fg: #fefefe;
    --fg-shading: #f5f5f5;

    --chip-bg: #e5e8ee;
    --chip-bg-active: #dfe2e8;
    --chip-text-light: #6e7273;

    --icon-default: #717075;
    --icon-chat: #717676;

    --box-bg: #242627;
    --box-shading: #1a1e1f;
    --box-fg: #151718;
    --box-button-border: #363a3b;
    --box-button-border-hover: #3b3f40; /* 5% lighter */
    --box-button-border-active: #3f4344; /* 5% lighter */

    --item-bg-start: #323337;
    --item-bg-end: #0b2e30;
    --item-bg-hover: #2e3133; /* 5% lighter */
    --item-bg-active: #2f3234; /* 5% lighter */
    --item-selected: #0084ff;

    --item-list-header: #3b4242;
    --item-list-button: #7b7f82;
    --item-list-button-hover: #8b8f92; /* 10% lighter */

    --chat-bg: #f3f5f7;

    --green: #3ddd77;

    --blue-normal: #0084ff;
    --blue-hover: #0f8bff;
    --blue-active: #1f93ff;
}

[data-theme="dark"] {
    --text-light: #1a1c1e;
    --text-dark: #f5f5f5;
    --text-def-white: #f5f5f5;

    --bg: #151718;
    --bg-2: #181a1c;

    --fg: #1e1e1e;
    --fg-shading: #f5f5f5;

    --chip-bg: hsl(200, 7%, 9%);
    --chip-bg-active: hsl(200, 7%, 19%);
    --chip-text-light: hsl(192, 2%, 50%);

    --icon-default: #717075;
    --icon-chat: #717676;

    --box-bg: #242627;
    --box-shading: #1a1e1f;
    --box-fg: #151718;
    --box-button-border: #b5b9ba;
    --box-button-border-hover: #c0c3c4; /* 5% lighter */
    --box-button-border-active: #cacdce; /* 5% lighter */

    --item-bg-start: #323337;
    --item-bg-end: #0b2e30;
    --item-bg-hover: #2e3133; /* 5% lighter */
    --item-bg-active: #2f3234; /* 5% lighter */
    --item-selected: #0084ff;

    --item-list-header: #3b4242;
    --item-list-button: #7b7f82;
    --item-list-button-hover: #8b8f92; /* 10% lighter */

    --chat-bg: #242627;

    --green: #3ddd77;

    --blue-normal: #0084ff;
    --blue-hover: #0f8bff;
    --blue-active: #1f93ff;
}