/* Dark theme */
[data-theme="dark"] {
  --background-color: #0e0e10;
  --text-color: #ffffff;
  --input-background-color: #3b364b;
  --input-text-color: #ffffff;
  --input-placeholder-color: #ffffff;
  --input-icon-color: #ffffff;
}

/* Light theme */
[data-theme="light"] {
  --background-color: #ffffff;
  --text-color: #0e0e10;
  --input-background-color: #ffffff;
  --input-text-color: #0e0e10;
  --input-placeholder-color: #0e0e10;
  --input-icon-color: #0e0e10;
}

:root {
  --black: #000000;
  --white: #ffffff;
}
