.content {
  @apply w-full h-dvh flex flex-col justify-center items-center gap-2 relative px-2;

  p {
    @apply text-lg text-center mt-6;
  }
}

.items {
  @apply flex gap-4 w-full justify-center mt-12;
}
