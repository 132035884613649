.modelPopup {
  @apply w-[94vw] sm:w-[80vw] max-w-4xl min-h-[500px] max-h-[500px] mx-auto text-chip-text-light font-medium overflow-y-auto;
  @apply bg-fg absolute z-50 top-[calc(100%+1rem)] -translate-x-1/2 left-1/2;
  @apply rounded-2xl p-3 border-[1px] border-chip-bg;
  @apply flex flex-col shadow-2xl;

  h2 {
    @apply text-center;
  }
}

.header {
  @apply flex flex-col justify-center items-center;
}

.chainingRow {
  @apply flex gap-3 mb-3;
}

.modelContent {
  @apply flex flex-row grow;
}

.itemList {
  @apply overflow-y-auto;
}

.selectedModels {
  @apply min-w-[160px] mt-3;
  @apply flex flex-col gap-3;

  h2 {
    @apply pr-4;
  }
}
.availableModels {
  @apply w-full grow mt-3;

  .itemList {
    @apply flex flex-col flex-wrap gap-3 mt-3;
  }
}
.selectedModels + .availableModels {
  @apply border-l-[1px] border-chip-bg pl-3;
}

.itemSection h3 {
  @apply flex items-center mb-2 gap-1;
}
.sectionGroup {
  @apply flex flex-wrap gap-2.5;
}

.selected.modelItem {
  @apply !bg-[hsl(132,80%,50%)] bg-opacity-50;
}
.modelItem {
  @apply min-w-16;
  @apply rounded-2xl border-[1px] border-chip-bg cursor-pointer p-1.5 flex flex-col text-text-dark;
  @apply hover:bg-chip-bg active:bg-opacity-50 select-none;
}
