.linedBackground {
  @apply absolute left-0 right-0 top-0 bottom-0 max-h-[600px] max-w-[925px] w-full h-full p-4 m-auto;
}

.container {
  @apply relative w-full h-full;
}

.hLeft {
  @apply mr-auto ml-5 md:ml-20;
}

.hRight {
  @apply ml-auto mr-5 md:mr-20;
}

.dLeft {
  @apply mx-auto -rotate-45 opacity-50 md:opacity-75;
}
.dRight {
  @apply mx-auto rotate-45 opacity-50 md:opacity-75;
}

.vTop {
  @apply mb-auto mt-5 md:mt-20;
}

.vBottom {
  @apply mt-auto mb-5 md:mb-20;
}

.hLeft,
.hRight,
.dLeft,
.dRight,
.vTop,
.vaTop,
.vBottom {
  @apply absolute inset-0 bg-gray-200 bg-opacity-50;
}

.vatContainer {
    @apply absolute h-[4px] w-full mb-auto mt-5 md:mt-[79px];
    mask-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 1) 10%,
        rgba(0, 0, 0, 1) 90%,
        rgba(0, 0, 0, 0)
      );
}
.vabContainer {
    @apply absolute h-[4px] w-full mt-auto mb-5 md:mb-[79px] bottom-0;
    mask-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 1) 10%,
        rgba(0, 0, 0, 1) 90%,
        rgba(0, 0, 0, 0)
      );
}
.vaTop, .vaBottom {
  @apply h-[2px] w-full !mx-auto my-auto;
  @apply absolute inset-0 blur-[0.5px] z-10 w-12;
  background: linear-gradient(to right, #801dff00, #801dff, #801dff00);

}

.hLeft,
.hRight,
.dLeft,
.dRight {
  @apply h-full w-0.5;
  mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 1) 10%,
    rgba(0, 0, 0, 1) 90%,
    rgba(0, 0, 0, 0)
  );
}
.vTop,
.vBottom {
  @apply h-0.5 w-full;
  mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 1) 10%,
    rgba(0, 0, 0, 1) 90%,
    rgba(0, 0, 0, 0)
  );
}
