@keyframes slideInUp {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0);
    }
}
.slideInUp {
    animation: slideInUp 150ms ease-out;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.fadeIn {
    animation: fadeIn 150ms ease-out;
}

@keyframes slideInUpFadeIn {
    0% {
        transform: translateY(50%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
.slideInUpFadeIn {
    animation: slideInUpFadeIn 250ms ease-in-out;
}

@keyframes pingPongRight {
    0%, 100% {
        transform: translateX(-60vw);
    }
    50% {
        transform: translateX(60vw);
    }
}
.pingPongRight {
    animation: pingPongRight 10s infinite ease-in-out;
}

@keyframes pingPongLeft {
    0%, 100% {
        transform: translateX(60vw);
    }
    50% {
        transform: translateX(-60vw);
    }
}
.pingPongLeft {
    animation: pingPongLeft 10s infinite ease-in-out;
}